<template>
  <v-container>
    <div class="ml-2"><PageTitle text="Sources" /></div>
    <div>
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="primary"
        class="ml-2 mt-3 white--text ft"
        style="font-size: 12px"
        @click="dialog = true"
      >
        New Source<v-icon size="20" color="white">mdi-plus</v-icon>
      </v-btn>

      <!--      <button class="button ml-2 mt-6" @click.stop="create">-->
      <!--        New <v-icon size="20" color="white">mdi-plus</v-icon>-->
      <!--      </button>-->

      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="blue-grey--text ft"
      >
        All Sources
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="sources"
              item-key="source"
              :server-items-length="total"
              :options.sync="options"
              loading-text="Loading... Please wait"
              :loading="loading"
              class="elevation-1 ft box-shadow-light"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-avatar
                      color="blue-grey"
                      style="margin-right: -80px"
                      size="25"
                    >
                      <span class="white--text subtitle-1 ">{{
                        item.name | firstLetterFunction
                      }}</span>
                    </v-avatar>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.address }}</td>
                  <td>{{ item.phoneNumber }}</td>
                  <td class="justify-left layout px-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mx-1"
                          link
                          :to="{
                            name: 'master.source-detail',
                            params: { id: item.id }
                          }"
                          dark
                          v-on="on"
                        >
                          <v-icon color="blue-grey">visibility</v-icon>
                        </v-btn>
                      </template>
                      <span>View</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mx-1"
                          tag="a"
                          link
                          @click="openDialog(item)"
                          dark
                          v-on="on"
                        >
                          <v-icon color="blue-grey">mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mx-0"
                          @click="deleteFunction(item)"
                          dark
                          v-on="on"
                        >
                          <v-icon color="pink" size="19"
                            >mdi-trash-can-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >
    <v-dialog v-model="dialog" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Create Source</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Name"
                    ref="name"
                    class="mx-5 px-2 required"
                    v-model="name"
                    :rules="[rules.requiredName]"
                  >
                  </v-text-field>

                  <v-text-field
                    dense
                    outlined
                    label="Address"
                    ref="location"
                    class="mx-5 px-2 required"
                    v-model="location"
                    :rules="[rules.requiredLoc]"
                  >
                  </v-text-field
                ></v-row>
                <v-row>
                  <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                  <!--                    ><v-select-->
                  <!--                      dense-->
                  <!--                      :items="regions"-->
                  <!--                      v-model="region"-->
                  <!--                      ref="region"-->
                  <!--                      label="Region"-->
                  <!--                      outlined-->
                  <!--                      :rules="[rules.requiredRegion]"-->
                  <!--                      class="mx-2 px-2 my-n4 required"-->
                  <!--                    ></v-select-->
                  <!--                  ></v-col>-->

                  <v-text-field
                    dense
                    outlined
                    label="Phone"
                    class="mx-5 px-2 required"
                    ref="phone"
                    v-model="phone"
                    :rules="[rules.min]"
                  >
                  </v-text-field>
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Latitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lat"-->
                  <!--                    v-model="lat"-->
                  <!--                    :rules="[rules.requiredLat]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Longitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lng"-->
                  <!--                    v-model="lng"-->
                  <!--                    :rules="[rules.requiredLng]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="name === null"
                  small
                  color="blue"
                  class="  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  @click.stop="add"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-progress-linear
            v-show="progress"
            indeterminate
            color="cyan"
          ></v-progress-linear>
          <v-card-title class="blue-grey--text">
            <h5>
              Delete this source?
            </h5>
          </v-card-title>

          <v-card-text>
            <h4>{{ sourceInfo.name }}</h4></v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text @click="dialogDelete = false">
              No
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteSource()">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialogUpdate" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Update Source</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Name"
                    ref="name"
                    class="mx-5 px-2 required"
                    v-model="itemName"
                    :rules="[rules.requiredName]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Address"
                    ref="location"
                    class="mx-5 px-2 required"
                    v-model="itemLocation"
                    :rules="[rules.requiredLoc]"
                  >
                  </v-text-field
                ></v-row>
                <v-row>
                  <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                  <!--                    >-->
                  <!--                  <v-select-->
                  <!--                      dense-->
                  <!--                      :items="regions"-->
                  <!--                      v-model="itemRegion"-->
                  <!--                      ref="region"-->
                  <!--                      label="Region"-->
                  <!--                      outlined-->
                  <!--                      :rules="[rules.requiredRegion]"-->
                  <!--                      class="mx-2 px-2 my-n4 required"-->
                  <!--                    ></v-select-->
                  <!--                  ></v-col>-->

                  <v-text-field
                    dense
                    outlined
                    label="Phone"
                    class="mx-5 px-2 required"
                    ref="phone"
                    v-model="itemPhone"
                    :rules="[rules.min]"
                  >
                  </v-text-field>
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Latitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lat"-->
                  <!--                    v-model="itemLat"-->
                  <!--                    :rules="[rules.requiredLat]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Longitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lng"-->
                  <!--                    v-model="itemLng"-->
                  <!--                    :rules="[rules.requiredLng]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialogUpdate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  class="blue  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  text
                  @click.stop="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../../../components/PageTitle";
import * as axios from "axios";
export default {
  name: "Sources",
  components: { PageTitle },
  data() {
    return {
      progress: false,
      dialog: false,
      formHasErrors: false,
      showSnackBar: false,
      dialogUpdate: false,
      phone: "",
      lng: "",
      lat: "",
      message: "",
      status: "",
      name: "",
      itemId: "",
      itemName: "",
      itemLat: "",
      itemLng: "",
      itemPhone: "",
      itemLocation: "",
      itemRegion: "",
      sources: [],
      dialogDelete: false,
      region: "",
      location: "",
      sourceInfo: {},
      itemObject: {
        name: null,
        location: null,
        phone: null
      },
      regions: [
        "Ahafo",
        "Ashanti",
        "Bono",
        "Bono East",
        "Central",
        "Eastern",
        "Greater Accra",
        "Northern",
        "North East",
        "Oti ",
        "Savannah",
        "Upper East",
        "Upper West",
        "Volta",
        "Western",
        "Western North"
      ],
      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredLoc: value =>
          !!value || value === null || "Location field is required.",
        requiredName: value => !!value || value === null || "Name is required.",
        requiredRegion: value =>
          !!value || value === null || "Region is Required.",
        requiredLat: value =>
          !!value || value === null || "Latitude is Required.",
        requiredLng: value =>
          !!value || value === null || "Longitude is Required."
      },
      loading: true,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 5
      }
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          value: "",
          filterable: false,
          sortable: false
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Address", value: "address" },
        { text: "Telephone", value: "phoneNumber" },
        { text: "Actions", value: "" }
      ];
    }
  },
  watch: {
    options: {
      handler(value) {
        this.getSources(value.page, value.itemsPerPage);
      },
      deep: true
    }
  },

  created() {
    this.getSources(this.options.page, this.options.itemsPerPage);
  },
  filters: {
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    }
  },

  methods: {
    openDialog(value) {
      this.dialogUpdate = true;
      this.itemId = value.id;
      this.itemName = value.name;
      this.itemLocation = value.address;
      this.itemPhone = value.phoneNumber;
    },

    deleteFunction(item) {
      this.dialogDelete = true;
      this.sourceInfo = item;
    },

    deleteSource() {
      this.progress = true;

      setTimeout(() => {
        axios({
          method: "delete",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/sources/${this.sourceInfo.id}`
        })
          .then(response => {
            this.dialogDelete = false;
            if (response.status === 200) {
              this.status = "success";
              this.message = "Source has been deleted successfully.";
              this.showSnackBar = true;
              this.dialogUpdate = false;
              this.getSources(this.options.page, this.options.itemsPerPage);
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              //console.log(err);
            }
          });
      }, 2000);
    },

    save() {
      this.progress = true;
      console.log(this.itemId);
      setTimeout(() => {
        axios({
          method: "put",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/sources/${this.itemId}`,
          data: {
            name: this.itemName,
            address: this.itemLocation,
            phoneNumber: this.itemPhone
          }
        })
          .then(response => {
            //console.log(response);
            this.status = "success";
            this.message = response.data.message;
            this.showSnackBar = true;
            this.dialogUpdate = false;
            this.getSources(this.options.page, this.options.itemsPerPage);
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              this.progress = false;
              this.status = "warning";
              this.showSnackBar = true;
              this.message = "Failed to update this branch.";
            }
          });
      }, 5000);
    },

    add() {
      if (this.name !== "" && this.location !== "" && this.phone !== "") {
        this.progress = true;
        setTimeout(() => {
          // let coords = {
          //   latitude: this.lat,
          //   longitude: this.lng
          // };
          axios({
            method: "post",
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`
            },
            url: `${process.env.VUE_APP_BASE}/sources`,
            data: {
              name: this.name,
              phoneNumber: this.phone,
              address: this.location
            }
          })
            .then(response => {
              this.getSources(this.options.page, this.options.itemsPerPage);
              this.dialog = false;
              if (response.status === 201) {
                this.status = "success";
                this.message = response.data.message;
                this.showSnackBar = true;
                this.name = this.itemObject.name;
                this.location = this.itemObject.location;
                this.phone = this.itemObject.phone;
              }
            })
            .catch(err => {
              if (err.response.status === 401) {
                this.$router.replace({ path: "/login" });
              } else {
                this.progress = false;
                this.dialog = true;
                this.status = "warning";
                this.message = "Failed to add a new source.";
              }
            });
        }, 1000);
      } else {
        this.showSnackBar = true;
        this.status = "warning";
        this.message = "All fields required.";
      }
    },

    getSources(page, itemsPerPage) {
      this.progress = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/sources?paginated=true&page=${page}&limit=${itemsPerPage}`
      })
        .then(response => {
          //console.log(response);
          if (response.status === 200) {
            this.progress = false;
            this.loading = false;
            this.sources = response.data.paginateObj.docs;
            this.options.page = response.data.paginateObj.page;
            this.options.itemsPerPage = parseInt(
              response.data.paginateObj.limit
            );
            this.total = response.data.paginateObj.total;
          }
        })
        .catch(err => {
          this.loading = false;
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
